import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, Notification, NotificationLight, Wrapper } from './styled';
import BattleCard from '../../containers/BattleCard';
import { WordsDetection } from '../common';
import CallResultPopup from '../CallResultPopup';
import withLocalization from '../../localization/withLocalization';
import { errorCodes } from '../../utils';
import CallInformation from '../../containers/CallInformation';
import Transcript from '../../containers/Transcript';
import UserInfo from '../../containers/UserInfo';
import CallButton from './CallButton';
import { proto } from '../../background/services/AsrProto';
import { ScrollableContainer } from '../common/ScrollableContainer';
import { NoKeywordsCard } from './NoKeywordsCard';
import { OptInControls } from './OptOutControls';
import Prompt from '../common/Prompt';
import ConversionModal from './Conversion';
import {
    DISPLAY_CONVERSION_MODAL,
    DISPLAY_MOTIVATIONAL_PROMPT,
    isFeatureEnabled
} from '../../config/features';
import { IS_UI_ONLY_MODE } from '../../config/electron';
import CallSuggestions from '../../containers/CallSuggestions';
import { EXPLICIT_INTEGRATION_SOURCE } from '../../background/constants';

function DoSayCard({ whitelist, userInterfaceConfig, ...restProps }) {
    return (
        <CardWrapper>
            <WordsDetection
                moveSaidDoSayWordsToBottom={
                    userInterfaceConfig.moveSaidDoSayWordsToBottom
                }
                displayDoSayAlternatives={userInterfaceConfig.displayDoSayAlternatives}
                displayStartStopKeywords={
                    userInterfaceConfig.explicitIntegrationSource ===
                    EXPLICIT_INTEGRATION_SOURCE.KEYPHRASE_AUDIO_EVENT
                }
                {...restProps}
                type="whitelist"
                words={whitelist}
            />
        </CardWrapper>
    );
}

DoSayCard.propTypes = {
    whitelist: PropTypes.array.isRequired,
    userInterfaceConfig: PropTypes.object.isRequired
};

function DontSayCard({ blacklist, userInterfaceConfig, ...restProps }) {
    return (
        <CardWrapper>
            <WordsDetection
                moveSaidDoSayWordsToBottom={
                    userInterfaceConfig.moveSaidDoSayWordsToBottom
                }
                {...restProps}
                type="blacklist"
                words={blacklist}
            />
        </CardWrapper>
    );
}

DontSayCard.propTypes = {
    blacklist: PropTypes.array.isRequired,
    userInterfaceConfig: PropTypes.object.isRequired
};

class Trainer extends Component {
    static propTypes = {
        whitelist: PropTypes.array.isRequired,
        blacklist: PropTypes.array.isRequired,
        elapsedSeconds: PropTypes.number,
        whitelistPhrasesSpottingScore: PropTypes.object,
        lastCallPhraseSpottingScore: PropTypes.object,
        isCallResultVisible: PropTypes.bool.isRequired,
        isLastCallResult: PropTypes.bool.isRequired,
        isNotificationShown: PropTypes.bool.isRequired,
        recordCall: PropTypes.bool.isRequired,
        closeResultPopup: PropTypes.func.isRequired,
        localization: PropTypes.object.isRequired,
        isCallStarted: PropTypes.bool.isRequired,
        sendManualCallEvent: PropTypes.func.isRequired,
        userInterfaceConfig: PropTypes.object.isRequired,
        isManualCallButtonEnabled: PropTypes.bool.isRequired,
        setManualButtonDisabled: PropTypes.func.isRequired,
        saveConversion: PropTypes.func.isRequired,
        configLoaded: PropTypes.bool.isRequired,
        asrConfigErrors: PropTypes.object.isRequired,
        reload: PropTypes.func.isRequired,
        manualOptIn: PropTypes.func.isRequired,
        manualOptOut: PropTypes.func.isRequired,
        manualOptStop: PropTypes.func.isRequired,
        userScoreError: PropTypes.object,
        optInOn: PropTypes.bool.isRequired,
        isOptOut: PropTypes.bool.isRequired,
        stopRecordingCustomer: PropTypes.bool.isRequired,
        callHold: PropTypes.bool.isRequired,
        prompt: PropTypes.object.isRequired,
        init: PropTypes.func.isRequired,
        closeConversionModal: PropTypes.func.isRequired,
        isCallEnded: PropTypes.bool.isRequired,
        callDuration: PropTypes.number.isRequired,
        userId: PropTypes.number,
        callId: PropTypes.string,
        conversionModalOpen: PropTypes.bool.isRequired,
        callEventNotification: PropTypes.string,
        keyPhraseIntegrationWords: PropTypes.object
    };

    static defaultProps = {
        elapsedSeconds: null,
        whitelistPhrasesSpottingScore: null,
        lastCallPhraseSpottingScore: null,
        userScoreError: null,
        userId: null,
        callId: null,
        callEventNotification: null,
        keyPhraseIntegrationWords: null
    };

    state = {
        isResultShown: false
    };

    componentDidUpdate() {
        if (this.props.isCallResultVisible && !this.state.isResultShown) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(() => ({ isResultShown: true }));
        }
    }

    componentDidMount() {
        this.props.init();
    }

    closeConversionModal = () => {
        this.props.closeConversionModal();
    };

    _renderSuggestions = () => {
        return <CallSuggestions />;
    };

    _renderPrompt = () => {
        const { prompt, isCallStarted } = this.props;

        if (
            !isFeatureEnabled(this.props.userInterfaceConfig, DISPLAY_MOTIVATIONAL_PROMPT)
        ) {
            return null;
        }

        if (isCallStarted) {
            return null;
        }

        return <Prompt prompt={prompt.currentPrompt} />;
    };

    _renderWordDetection = () => {
        const {
            blacklist,
            whitelist,
            asrConfigErrors,
            localization,
            reload,
            optInOn,
            userInterfaceConfig: {
                displayCallOptOutControls,
                explicitIntegrationSource,
                displayDontSaySection = true
            },
            isCallStarted,
            callHold
        } = this.props;
        const { noWhitelist = false, noBlacklist = false } = asrConfigErrors;

        if (noWhitelist && noBlacklist) {
            return <NoKeywordsCard localization={localization} />;
        }

        const isEnabled =
            isCallStarted && !callHold && (!displayCallOptOutControls || optInOn);

        return (
            <Fragment>
                <DoSayCard
                    {...this.props}
                    reload={reload}
                    whitelist={whitelist}
                    noKeywords={noWhitelist}
                    isEnabled={isEnabled}
                    isButtonShown={
                        displayCallOptOutControls ||
                        explicitIntegrationSource ===
                            proto.ExplicitIntegrationSource.I2X_MANUAL
                    }
                />
                {displayDontSaySection ? (
                    <DontSayCard
                        {...this.props}
                        reload={reload}
                        blacklist={blacklist}
                        noKeywords={noBlacklist}
                        isEnabled={isEnabled}
                    />
                ) : null}
            </Fragment>
        );
    };

    _renderCallPopup = () => {
        if (!this.state.isResultShown) {
            return false;
        }

        const {
            whitelistPhrasesSpottingScore,
            lastCallPhraseSpottingScore,
            isCallResultVisible,
            isLastCallResult,
            closeResultPopup,
            optInOn,
            recordCall,
            userInterfaceConfig: { displayCallOptOutControls }
        } = this.props;

        // eslint-disable-next-line no-unused-vars
        let isVisible = isCallResultVisible && (optInOn || !displayCallOptOutControls);
        // isVisible = isVisible && recordCall;
        /**
         * The problem here is that because of how the reload works
         * the whole popup is rendered in weird places, this is a hack
         * to go around it until we have time to properly solve that problem
         * by rewritin the WS open/close solution to not rely on reloads.
         */
        if (displayCallOptOutControls) {
            const wasOptInPressed = localStorage.getItem('wasOptInPressed');
            if (JSON.parse(wasOptInPressed) === false) {
                isVisible = false;
            }
        }

        return (
            <CallResultPopup
                {...(isLastCallResult
                    ? lastCallPhraseSpottingScore
                    : whitelistPhrasesSpottingScore)}
                isVisible={isVisible}
                onClose={closeResultPopup}
                isLastCallResult={isLastCallResult}
                recordCall={recordCall}
            />
        );
    };

    _renderNotification = () => {
        const { isNotificationShown, localization } = this.props;

        if (!isNotificationShown) {
            return;
        }

        // TODO: add animation
        return (
            <Notification>
                {localization.getText(
                    `warnings.${errorCodes.background.SLOW_NETWORK_WARN}`
                )}
            </Notification>
        );
    };

    _renderCallEventNotification = () => {
        const { callEventNotification, localization } = this.props;

        if (!callEventNotification) {
            return;
        }

        // TODO: add animation
        return (
            <NotificationLight>
                {localization.getText(
                    `trainer.callEventNotification.${callEventNotification}`
                )}
            </NotificationLight>
        );
    };

    _renderCallButton = () => {
        const {
            sendManualCallEvent,
            isCallStarted,
            userInterfaceConfig,
            isManualCallButtonEnabled,
            setManualButtonDisabled,
            localization
        } = this.props;

        if (
            ![
                EXPLICIT_INTEGRATION_SOURCE.I2X_MANUAL_CALL_EVENT,
                EXPLICIT_INTEGRATION_SOURCE.KEYPHRASE_AUDIO_EVENT
            ].includes(userInterfaceConfig.explicitIntegrationSource) ||
            (userInterfaceConfig.explicitIntegrationSource ===
                EXPLICIT_INTEGRATION_SOURCE.KEYPHRASE_AUDIO_EVENT &&
                !isCallStarted) ||
            IS_UI_ONLY_MODE
        ) {
            return null;
        }

        return (
            <CallButton
                sendManualCallEvent={sendManualCallEvent}
                isCallStarted={isCallStarted}
                isManualCallButtonEnabled={isManualCallButtonEnabled}
                setDisabled={setManualButtonDisabled}
                localization={localization}
            />
        );
    };

    _renderCallOptInControls = () => {
        const {
            userInterfaceConfig: { displayCallOptOutControls = false } = {},
            isCallStarted
        } = this.props;

        const manualOptInCb = () => {
            localStorage.setItem('wasOptInPressed', true);
            this.props.manualOptIn();
        };

        if (displayCallOptOutControls) {
            return (
                <OptInControls
                    optInOn={this.props.optInOn}
                    isOptOut={this.props.isOptOut}
                    stopRecordingCustomer={this.props.stopRecordingCustomer}
                    localization={this.props.localization}
                    manualOptIn={manualOptInCb}
                    manualOptOut={this.props.manualOptOut}
                    manualOptStop={this.props.manualOptStop}
                    isCallStarted={isCallStarted}
                />
            );
        }
    };

    _renderState = () => {
        //
        const { userInterfaceConfig: { displayRealTimeHints = true } = {} } = this.props;

        return (
            <Fragment>
                {/* {noTeam && <NoTeamCard localization={localization} reload={reload} />} */}
                <Transcript />
                {this._renderCallOptInControls()}
                <ScrollableContainer>
                    <BattleCard />
                    {this._renderSuggestions()}
                    {this._renderPrompt()}
                    {this._renderNotification()}
                    {this._renderCallEventNotification()}
                    {this._renderWordDetection()}
                    {displayRealTimeHints ? <CallInformation /> : null}
                    {this._renderCallPopup()}
                    {this._renderCallButton()}
                    {this._renderConversionModal()}
                    <UserInfo />
                </ScrollableContainer>
            </Fragment>
        );
    };

    render() {
        return <Wrapper data-test="Trainer">{this._renderState()}</Wrapper>;
    }

    _renderConversionModal() {
        if (
            !this.props.conversionModalOpen ||
            !isFeatureEnabled(this.props.userInterfaceConfig, DISPLAY_CONVERSION_MODAL)
        ) {
            return null;
        }
        const { saveConversion, callId, userId, localization } = this.props;

        return (
            <ConversionModal
                onFinish={this.closeConversionModal}
                saveConversion={saveConversion}
                callId={callId}
                userId={userId}
                localization={localization}
            />
        );
    }
}

export const PureTrainer = Trainer;
export default withLocalization(Trainer);
