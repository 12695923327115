import { types } from '../actions/config';

export const initialState = {
    pending: false,
    app: null,
    bonusScore: 3
};

export default function configReducer(state = initialState, { type, payload, error } = {}) {
    switch (type) {
        case types.UPDATE_APP_CONFIG:
        case types.GET_APP_CONFIG: {
            return {
                ...state,
                pending: true
            };
        }


        case types.SET_RECORD_CALL: {
            const {recordCall} = payload

            return {
                ...state,
                app: {
                    ...state.app,
                    recordCall,
                }
            };
        }

        case types.UPDATE_APP_CONFIG_SUCCESS:
        case types.GET_APP_CONFIG_SUCCESS: {
            const {
                saveStream,
                realtimeOnlyCallsPercent,
                suggestionCallFrequency,
                recognition: {
                    system: { forgetStream }
                },
                callDetection: {
                    useMicrophone,
                    integrationMethod,
                    explicitIntegrationSource,
                    prefixOffset = 0,
                    optInGracePeriodMsec,
                    explicitPingPeriodMsec,
                    appWhitelist,
                } = {},
                recognitionUseSystem,
                enableAnonymization,
                enableAsrDebug,
                enableCallMetadataOnly,
                enableCallTranscriptOnly,
                enableRecorder,
                defaultCallMode,
                absoluteSilenceDefinition: {
                    initialThresholdMax,
                    initialThresholdMin,
                    additionalIntervals,
                    disableDynamicThreshold,
                    overrideDefaultInitialThreshold,
                    consecutiveAbsoluteSilenceToEndVoicedChunkMsec
                },
                userInterface: { displayCallOptOutControls, displayGamificationWeekly, displayGamificationPerCall, displayRecordingState }
            } = payload.config

            window.displayGamificationWeekly = displayGamificationWeekly;
            window.displayRecordingState = displayRecordingState;
            return {
                ...state,
                app: {
                    saveStream,
                    realtimeOnlyCallsPercent,
                    suggestionCallFrequency,
                    recordCall: true,
                    recognition: { system: { forgetStream } },
                    callDetection: {
                        useMicrophone, // use microphone for call detection
                        integrationMethod,
                        explicitIntegrationSource,
                        prefixOffset,
                        optInGracePeriodMsec,
                        explicitPingPeriodMsec,
                        appWhitelist,
                        enableCallMetadataOnly: !!enableCallMetadataOnly,
                    },
                    recognitionUseSystem,
                    enableAnonymization,
                    enableCallMetadataOnly: !!enableCallMetadataOnly,
                    enableCallTranscriptOnly: !!enableCallTranscriptOnly,
                    enableRecorder: !!enableRecorder,
                    enableAsrDebug,
                    absoluteSilenceDefinition: {
                        initialThresholdMax,
                        initialThresholdMin,
                        additionalIntervals,
                        disableDynamicThreshold,
                        overrideDefaultInitialThreshold,
                        consecutiveAbsoluteSilenceToEndVoicedChunkMsec
                    },
                    enableManualOptInOptOut: displayCallOptOutControls,
                    defaultCallMode,
                    displayGamificationWeekly,
                    displayGamificationPerCall,
                    displayRecordingState
                },
                pending: false
            };
        }

        case types.UPDATE_APP_CONFIG_FAIL:
        case types.GET_APP_CONFIG_FAIL:
            return {
                ...state,
                pending: false,
                app: null,
                error
            };
        default:
            return state;
    }
}
