import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

import { Element as Input, Error, Placeholder, Wrapper } from './styled';

class TextInput extends Component {
    static propTypes = {
        type: PropTypes.string,
        placeholder: PropTypes.string,
        name: PropTypes.string,
        setupInput: PropTypes.func.isRequired,
        error: PropTypes.string,
        autoComplete: PropTypes.string
    };

    static defaultProps = {
        type: 'text',
        placeholder: '',
        name: '',
        error: '',
        autoComplete: ''
    };

    state = {
        isFocused: false,
        isEmpty: true,
        isValid: false,
        validationStatus: 'default'
    };

    componentDidMount() {
        this.props.setupInput(this.props.name, this.input);
    }

    _checkInputValidation() {
        const { isValid, isEmpty } = this.state;
        let validationStatus = 'error';
        if (isEmpty) {
            validationStatus = 'default';
        } else if (isValid) {
            validationStatus = 'valid';
        }
        this.setState({ validationStatus });
    }

    _validate() {
        const { type } = this.props;
        const { value = '' } = this.input;
        if (value) {
            switch (type) {
                case 'email':
                    this.setState({ isValid: validator.isEmail(value) });
                    break;
                case 'password':
                    this.setState({
                        isValid: validator.matches(
                            value,
                            /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9-_!&@?;:,/"'çßéèëàäùüö]{6,150}$/
                        )
                    });
                    break;
                default:
                    break;
            }
        }
        this._checkInputValidation();
    }

    _handleBlur(event) {
        event.preventDefault();
        this.setState({
            isFocused: false
        });
        this._validate();
    }

    _handleFocus(event) {
        event.preventDefault();
        this.setState({
            isFocused: true
        });
    }

    _handleChange(event) {
        event.preventDefault();

        this.setState({
            isEmpty: !this.input.value
        });

        if (this.input.value && this.state.isFocused) {
            setTimeout(() => {
                if (this.input) this._validate();
            }, 800);
        }
    }

    _renderText() {
        const { placeholder, error, name } = this.props;
        const { validationStatus } = this.state;
        if (validationStatus === 'error') {
            return <Error data-test={`${name}-error`}>{error}</Error>;
        }
        return placeholder;
    }

    render() {
        const { name } = this.props;
        return (
            <Wrapper>
                <Input
                    autoComplete={this.props.autoComplete}
                    name={this.props.name}
                    $type={this.props.type}
                    ref={ref => {
                        this.input = ref;
                    }}
                    onBlur={event => this._handleBlur(event)}
                    onFocus={event => this._handleFocus(event)}
                    onChange={event => this._handleChange(event)}
                    $validationStatus={this.state.validationStatus}
                    data-test={`${name}-input`}
                />
                <Placeholder
                    $focus={this.state.isFocused}
                    $empty={this.state.isEmpty}
                    data-test={`${name}-placeholder`}
                    $validationStatus={this.state.validationStatus}>
                    {this._renderText()}
                </Placeholder>
            </Wrapper>
        );
    }
}

export default TextInput;
