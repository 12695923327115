import styled from 'styled-components';
import { Wrapper as CardWrapper } from '../../Card/styled';
import { TopicWrapper } from '../../WordsList/KeyphraseStack/styled';
import CardRow from './CardRow';
import { colors } from '../../../../utils';

const Card = styled(CardWrapper)`
    min-height: 125px;
    max-height: ${props => (props.$isButtonShown ? '238px' : '280px')};
    padding: 16px 16px 10px 16px;
    background-color: ${props =>
        !props.$isEnabled && !props.$isBlacklist
            ? 'rgba(255, 255, 255, 0.5)'
            : 'rgba( 255,255, 255, 1)'};
    & > ${CardRow} {
        //opacity: ${props => (!props.$isEnabled && !props.$isBlacklist ? 0.5 : 1)};
    }
    & ${TopicWrapper} {
         opacity: ${props => (!props.$isEnabled && !props.$isBlacklist ? 0.5 : 1)};
    }

    opacity: ${props => (!props.$isEnabled && props.$isBlacklist ? 0.5 : 1)};
    overflow-y: auto;
    position: relative;
    outline-style: none;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.gray.iron};
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
    }
`;

export default Card;
