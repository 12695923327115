import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { CardWrapper } from '../styled';
import Card from '../../common/Card';
import { NoKeywords as NoKeywordsIcon } from '../../common/icons';
import colors from '../../../utils/colors';

const Message = styled.div`
    font-size: 14px;
    color: ${colors.gray.dark};
    line-height: 1.4;
`;

export function NoKeywordsCard(props) {
    const { localization } = props;

    return (
        <CardWrapper>
            <Card style={{ minHeight: 'auto' }}>
                <NoKeywordsIcon style={{ margin: '25px auto 23px', display: 'block' }} />
                <Message>
                    {localization.getText(`trainer.asr_config_errors.no_keywords`)}
                </Message>
            </Card>
        </CardWrapper>
    );
}

NoKeywordsCard.propTypes = {
    localization: PropTypes.object.isRequired
};
