import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { colors } from '../../utils';
import CrossIcon from '../common/icons/CrossIcon';

const modalNode = document.getElementById('modal-root');

const ModalOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.75);
`;

const ModalWindow = styled.div`
    width: 100%;
    max-width: 300px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 11px 0 rgb(158, 158, 158);
    padding: 16px;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const ModalTitle = styled.div`
    font-weight: 600;
`;

const ModalClose = styled.div`
    margin-left: 16px;
    cursor: pointer;

    &:hover {
        svg {
            fill: ${colors.gray.jumbo};
        }
    }
`;

const ModalBody = styled.div``;

export default function Modal({ title, children, onClose }) {
    const modal = (
        <ModalOverlay>
            <ModalWindow>
                <ModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                    <ModalClose onClick={() => onClose()}>
                        <CrossIcon size={22} />
                    </ModalClose>
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
            </ModalWindow>
        </ModalOverlay>
    );

    return createPortal(modal, modalNode);
}

Modal.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    onClose: PropTypes.func
};
