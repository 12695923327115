/* eslint-disable no-unreachable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PlusIcon from './PlusIcon';

import {
    Wrapper,
    TopicWrapper,
    Topic,
    ListWrapper,
    List,
    Keyword,
    TopicIcon
} from './styled';

// StackIcon is removed based on DEVELOP-2868 ticket
// TODO: Leaving it commented until next release.
// import StackIcon from './StackIcon';
import StarIcon from './StarIcon';

import { words as wordsUtil } from '../../../../utils';

const { getSortedDetection } = wordsUtil;

class KeyphraseStack extends Component {
    static propTypes = {
        topic: PropTypes.string.isRequired,
        alternatives: PropTypes.array.isRequired,
        complements: PropTypes.array.isRequired,
        displayDoSayAlternatives: PropTypes.bool.isRequired
    };

    static wrapper = null;

    static scrollableContainerRef = null;

    static alternativesRef = null;

    state = {
        scrollTop: 0,
        availableSpace: 240
    };

    _handleScroll = event => {
        this.setState({
            scrollTop: event.target.scrollTop
        });
    };

    _setScrollableContainerRef = () => {
        const parent = this.wrapper?.parentNode?.parentNode?.parentNode;
        this.scrollableContainerRef = parent || this.wrapper;
    };

    componentDidMount() {
        if (this.alternativesRef) {
            const availableSpace = window.innerHeight - this.wrapper.offsetTop - 120;

            this._setScrollableContainerRef();
            this.scrollableContainerRef.addEventListener('scroll', this._handleScroll);
            this.setState({
                availableSpace
            });
        }
    }

    componentWillUnmount() {
        if (this.scrollableContainerRef) {
            this.scrollableContainerRef.removeEventListener('scroll', this._handleScroll);
        }
    }

    _hasAlternatives() {
        const { topic, alternatives } = this.props;

        return alternatives.length !== 0 && alternatives.some(alt => alt.text !== topic);
    }

    _hasComplements() {
        const { complements = [] } = this.props;

        return complements.length > 0;
    }

    _hasSuperKeyphrase = () => {
        const { alternatives } = this.props;

        return alternatives.some(alt => alt.points >= wordsUtil.SUPER_KEYWORD_THRESHOLD);
    };

    _wasDetected() {
        const { alternatives } = this.props;

        return alternatives.some(alt => alt.count && alt.count !== 0);
    }

    _renderTopic() {
        const { topic } = this.props;
        const isSuper = this._hasSuperKeyphrase();
        const isDetected = this._wasDetected();

        return (
            <TopicWrapper $detected={isDetected} $isSuper={isSuper}>
                {isSuper && <StarIcon />}
                <Topic>{topic}</Topic>
                {/* {this._hasAlternatives() && <StackIcon />} */}
                {this._hasComplements() && (
                    <TopicIcon>
                        <PlusIcon />
                    </TopicIcon>
                )}
            </TopicWrapper>
        );
    }

    _renderList() {
        const { alternatives, complements, topic } = this.props;
        const alternativesWithoutTopic = alternatives.filter(alt => alt.text !== topic);
        const hasAlternatives = this._hasAlternatives();

        return (
            <React.Fragment>
                {hasAlternatives &&
                    getSortedDetection(alternativesWithoutTopic, false).map(alt => (
                        <Keyword
                            title={alt.text}
                            key={alt.text}
                            $detected={alt.count !== 0}>
                            <span>{alt.text}</span>
                        </Keyword>
                    ))}
                {this._hasComplements() && (
                    <React.Fragment>
                        {hasAlternatives && <hr />}
                        {getSortedDetection(complements, false).map(alt => (
                            <Keyword
                                title={alt.text}
                                key={alt.text}
                                $detected={alt.count !== 0}>
                                <TopicIcon>
                                    <PlusIcon />
                                </TopicIcon>
                                <span>{alt.text}</span>
                            </Keyword>
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    _renderAlternativesList() {
        const { displayDoSayAlternatives } = this.props;

        if (!displayDoSayAlternatives) {
            return;
        }

        if (!this._hasAlternatives() && !this._hasComplements()) return;

        return (
            <ListWrapper
                ref={ref => {
                    this.alternativesRef = ref;
                }}>
                <List
                    style={{
                        maxHeight: this.state.availableSpace + (this.state.scrollTop - 10)
                    }}>
                    {this._renderList()}
                </List>
            </ListWrapper>
        );
    }

    render() {
        return (
            <Wrapper
                ref={ref => {
                    this.wrapper = ref;
                }}
                $scrollTop={this.state.scrollTop}
                $detected={this._wasDetected()}
                $stack={this._hasAlternatives()}>
                {this._renderTopic()}
                {this._renderAlternativesList()}
            </Wrapper>
        );
    }
}

export default KeyphraseStack;
