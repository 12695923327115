import {sendRequest} from "./common";
import {REQUESTS} from "../../constants";

export const logUserEvent = (eventType, callId = null, meta = null) => dispatch => {
    if(!eventType){
        return
    }

    const body = {
        eventType,
        timestamp: (new Date()).toISOString()
    }

    if(callId){
        body.callId = callId;
    }

    if(meta){
        body.meta = meta;
    }

    dispatch(
        sendRequest(REQUESTS.LOG_USER_EVENT, {
            payload: {
                body
            },
        })
    );
};
