import { connect } from 'react-redux';
import {getCallEventNotification} from "../../store/selectors/trainer";
import { Trainer } from '../../components';

import {
    setResultPopupVisibility,
    onManualCallEvent,
    changeCallButtonAvailability,
    userReload,
    manualOptOut,
    manualOptIn,
    manualOptStop,
    init,
    saveConversion,
    closeConversionModal
} from '../../store/actions/trainer';

const mapStateToProps = state => ({
    asrConfigErrors: state.trainer.asrConfigErrors,
    userScoreError: state.user.userScoreError,
    configLoaded: state.trainer.configLoaded,
    whitelist: state.trainer.whitelist,
    blacklist: state.trainer.blacklist,
    elapsedSeconds: state.trainer.elapsedSeconds,
    whitelistPhrasesSpottingScore: state.trainer.whitelistPhrasesSpottingScore,
    lastCallPhraseSpottingScore: state.trainer.lastCallPhraseSpottingScore,
    isCallResultVisible: state.trainer.isCallResultVisible,
    isLastCallResult: state.trainer.isLastCallResult,
    isNotificationShown: state.trainer.isNotificationShown,
    userInterfaceConfig: state.trainer.userInterfaceConfig,
    isCallStarted: state.trainer.isStarted,
    isManualCallButtonEnabled: state.trainer.isManualCallButtonEnabled,
    optInOn: state.trainer.optInOn,
    isOptOut: state.trainer.isOptOut,
    stopRecordingCustomer: state.trainer.stopRecordingCustomer,
    callHold: state.trainer.callHold,
    prompt: state.prompt,
    isCallEnded: state.trainer.isCallEnded,
    callDuration: state.trainer.callDuration,
    callId: state.trainer.callId,
    userId: state.user.userId,
    recordCall: state.trainer.recordCall,
    conversionModalOpen: state.trainer.conversionModalOpen,
    callEventNotification: getCallEventNotification(state),
    keyPhraseIntegrationWords: state.trainer.keyPhraseIntegrationWords,
});

const mapDispatchToProps = dispatch => {
    return {
        reload: () => dispatch(userReload()),
        init: () => dispatch(init()),
        manualOptIn: () => dispatch(manualOptIn()),
        manualOptOut: () => dispatch(manualOptOut()),
        manualOptStop: () => dispatch(manualOptStop()),
        closeResultPopup: () => dispatch(setResultPopupVisibility(false)),
        sendManualCallEvent: isStart => dispatch(onManualCallEvent(isStart)),
        setManualButtonDisabled: () => dispatch(changeCallButtonAvailability(false)),
        saveConversion: (payload) => dispatch(saveConversion(payload)),
        closeConversionModal: () => dispatch(closeConversionModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainer);
