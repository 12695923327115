import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextInputPlaceholder = styled.label`
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    left: 21px;
    text-align: left;
    color: #4a4a4a;
    opacity: ${props => (props.$validationStatus === 'error' ? '1' : '0.6')};

    ${props =>
        props.$focus && props.$empty
            ? `
                top: 8px;
                font-size: 12px;
            `
            : `
                top: 18px;
                font-size: 16px;
            `} ${props =>
        !props.$empty
            ? `
                top: 8px;
                font-size: 12px;
            `
            : null};
`;

TextInputPlaceholder.propTypes = {
    $validationStatus: PropTypes.string.isRequired,
    $focus: PropTypes.bool.isRequired,
    $empty: PropTypes.bool.isRequired
};

export default TextInputPlaceholder;
