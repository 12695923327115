import packageJson from '../../package.json';
import {BASE_URL, LOGGER_BASE_URL} from '../utils/config';

export const REQUESTS = {
    GET_USER_ASR_CONFIG: {
        method: 'GET',
        route: '/organizations/v1/users/me/config/asr',
        apiUrl: BASE_URL
    },
    COMPANY_LOGIN: {
        method: 'POST',
        route: '/organizations/v1/login/token',
        apiUrl: BASE_URL
    },
    REFRESH_TOKEN: {
        method: 'POST',
        route: '/organizations/v1/login/refresh',
        apiUrl: BASE_URL
    },
    GET_APP_CONFIG: {
        method: 'GET',
        route: '/organizations/v1/users/me/config/app',
        apiUrl: BASE_URL
    },
    GET_USER_SCORES: {
        method: 'GET',
        route: '/dashboard/gamification/me/score',
        apiUrl: BASE_URL,
    },
    PATCH_USER_PROFILE: {
        method: 'PATCH',
        route: '/organizations/v1/users/me/config',
        apiUrl: BASE_URL,
    },
    GET_USER_PROFILE: {
        method: 'GET',
        route: '/organizations/v1/users/me/config',
        apiUrl: BASE_URL,
    },
    SEND_LOG: {
        method: 'POST',
        route: `/log/?apikey=${LOGGER_BASE_URL.apiKey}`,
        apiUrl: LOGGER_BASE_URL.url
    },
    SAVE_CONVERSION: (callId) => ({
        method: 'PUT',
        route: `/calls/call/${callId}/conversion`,
        apiUrl: BASE_URL
    }),
    GET_NEW_SUGGESTIONS: {
        method: 'GET',
        route: '/calls/agent/coaching-suggestions',
        apiUrl: BASE_URL
    },
    LOG_USER_EVENT: {
        method: 'POST',
        route: '/events/v1/users',
        apiUrl: BASE_URL
    },
};

export const SAMPLE_RATE_HERTZ = 16000;

export const INFINITY_SNOOZE_SEC = 366 * 24 * 60 * 60;
export const INFINITY_SNOOZE_THRESHOLD = 24 * 60 * 60;

export const DAEMON_VERSION = packageJson.daemonVersion;

export const MAX_CALL_DETECTION_SESSION_DURATION = 6 * 60 * 60 * 1000;
export const MAX_CALL_DETECTION_SESSION_DURATION_FOR_KEYPHRASE_DETECTION = 30 * 60 * 1000;
export const CALL_STATE_TRANSITION_DURATION = 2000;
export const EXPLICIT_MODE_RELOAD_TIMEOUT = 60 * 1000;

export const RECORDER_ERROR_RELOAD_TIMEOUT = 2 * 1000;

export const wsConnectionClosingCodes = {
    DEFAULT: 1000,
    NO_RELOAD: 4998,
    RESTART: 4999,
};

export const CHANNEL = {
    UNKNOWN: 0,
    MICROPHONE: 1,
    SYSTEM: 2
};

export const ERR_INVALID_AUTH_SCHEME = "ERR_INVALID_AUTH_SCHEME";
export const ERR_REFRESH_FAILED = "refresh failed";

export const CallEvents = {
    START: 'start',
    END: 'end',
    PING: 'ping',
    CHANGE: 'change',
    HOLD: 'hold',
    UNHOLD: 'unhold',
}

export const CallMode = {
    UNKNOWN: 0,
    BOTH: 1,
    CUSTOMER: 2,
    AGENT: 3,
    META: 4,
    NONE: 5,
    DEFAULT: 6, // mean take call mode from app settings
}

export const MIN_CHUNK_BUFFER = 0.5 * 1000; // 0.5 sec
export const DEFAULT_CHUNK_BUFFER = MIN_CHUNK_BUFFER;

export const CALL_TIMER_UPDATE_INTERVAL = 0.5 * 1000; // 0.5 sec

export const EXPLICIT_INTEGRATION_SOURCE = {
    I2X_CALL_EVENT: 1,
    UCT_CALL_EVENT: 2,
    I2X_MANUAL_CALL_EVENT: 3,
    WIN_AUDIO_SESSION_EVENT: 4,
    COPS_I2X_EVENT: 5,
    ORECX: 6,
    KEYPHRASE_AUDIO_EVENT: 7,
    RECORDER_EVENT: 8,
}

export const META_INFO_PROPERTIES = [
    'agent_id',
    'company_id',
    'call_id',
    'meta',
    'call_recording_mode',
    'timestamp',
]

export const KEYPHRASE_DETECTION_SILENCE_TIMOUT = 5 * 60 * 1000; // 5 min

export const KEYPHRASE_GROUP_NAME_FOR_START = "Anruf Start Schlüsselwort";
export const KEYPHRASE_GROUP_NAME_FOR_STOP = "Anruf Stop Schlüsselwort";

export const CALL_NOTIFICATION_START_PHRASE = 'call_start_phrase'
export const CALL_NOTIFICATION_RESTART_PHRASE = 'call_restart_phrase'
export const CALL_NOTIFICATION_END_PHRASE = 'call_end_phrase'
export const CALL_NOTIFICATION_TIMOUT = 'call_timeout'

export const CALL_NOTIFICATION_DURATION = 5000

export const USER_EVENT_TYPE = {
    LOGIN: "login",
    LOGOUT: "logout",
    APP_DEACTIVATE: "app_deactivate",
    APP_ACTIVATE: "app_activate",
    CALL_START: "call_start",
    CALL_END: "call_end",
    CALL_HOLD: "call_hold",
    CALL_UNHOLD: "call_unhold",
};


