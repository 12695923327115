import styled from 'styled-components';

const TextInputWrapper = styled.div`
    position: relative;

    &:not(:first-child) {
        margin-top: 8px;
    }
`;

export default TextInputWrapper;
