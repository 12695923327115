import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { getSuggestionCallFrequency } from '../../store/selectors/trainer';
import { withLocation } from '../../utils/withLocation';

import { Header } from '../../components';
import withLocalization from '../../localization/withLocalization';
import {
    resumeTrainer,
    snoozeTrainer,
    setInfinitySnooze,
    manualOptIn,
    manualOptOut
} from '../../store/actions/trainer';
import {
    closeCallSuggestions,
    toggleCallSuggestions
} from '../../store/actions/callSuggestions';
import { doLogout } from '../../store/actions/user';
import { IS_UI_ONLY_MODE } from '../../config/electron';

const mapStateToProps = store => ({
    hasError: store.trainer.errorCode != null,
    showBoxShadow: !store.trainer.showTranscript,
    elapsedSeconds: store.trainer.elapsedSeconds,
    userScore: store.user.userScore,
    userScoreError: store.user.userScoreError,
    whitelistPhrasesSpottingScore: store.trainer.whitelistPhrasesSpottingScore,
    isTrainerActive: store.trainer.isTrainerActive,
    isUpdatingTrainerStatus: store.trainer.isSettingSnoozeTimer,
    displaySnoozingControls:
        store.trainer.userInterfaceConfig.displaySnoozingControls && !IS_UI_ONLY_MODE,
    showDashboardLink:
        store.trainer.userInterfaceConfig.showDashboardLink && !isEmpty(store.user.role),
    isVodafone: store.user.isVodafone,
    optInOn: store.trainer.optInOn,
    isOptOut: store.trainer.isOptOut,
    isCallStarted: store.trainer.isStarted,
    displayCallOptOutControls:
        store.trainer.userInterfaceConfig.displayCallOptOutControls,
    recordCall: store.trainer.recordCall,
    authToken: store.user.token,
    authWithPasswordRequired: store.user.authWithPasswordRequired,
    showSuggestionsButton:
        store.callSuggestions.suggestions.length > 0 &&
        getSuggestionCallFrequency(store) > 0,
    isCallSuggestionsNew: store.callSuggestions.isCallSuggestionsNew
});

export default withLocation(
    connect(mapStateToProps, {
        resumeTrainer,
        snoozeTrainer,
        setInfinitySnooze,
        manualOptIn,
        manualOptOut,
        doLogout,
        closeCallSuggestions,
        toggleCallSuggestions
    })(withLocalization(Header))
);
