import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const statusStyle = {
    valid: {
        border: `solid 2px ${colors.green.aquamarine}`
    },
    error: {
        border: `solid 1.5px ${colors.red.light_carmine_pink}`
    },
    default: {
        border: '2px none'
    }
};

const Element = styled.input.attrs(props => ({
    type: props.$type
}))`
    width: 100%;
    height: 56px;
    background-color: ${colors.white.smoke};
    outline: none;
    padding: 25px 24px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: ${colors.gray.charcoal};
    border-radius: 2px;
    border: ${props => statusStyle[props.$validationStatus].border};

    &[type='password'] {
        letter-spacing: 3px;
        font-weight: 700;
    }
`;

Element.propTypes = {
    $type: PropTypes.string,
    $validationStatus: PropTypes.string
};

Element.defaultProps = {
    $type: 'text',
    $validationStatus: 'default'
};

export default Element;
